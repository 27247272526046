<template>
  <div class="filter">
    <div class="filter__btn-block">
      <div class="filter__btn-left">
        <div class="filter__btn-left__label">{{ $t('Filter') }}</div>
        <div class="filter__btn-left__reset-btn" @click="resetFilter">
          {{ $t('Clear') }}
        </div>
      </div>
      <div @click="closeLeftSidebar" class="lg:tw-hidden">
        <zem-icon class="cursor-pointer" name="close" size="18" />
      </div>
    </div>
    <div class="filter__dropdown">
      <div class="zem-dropdown mb-0">
        <label class="filter__label tw-mt-2">Сущность</label>
        <ZemDropdownList
          v-model="selectedModel"
          :options="modelNotes"
          class="tw-mt-0"
          label="title"
          placeholder="Выбрать"
          @change="handlerFilter('model', $event)"
        ></ZemDropdownList>
      </div>
      <div class="zem-dropdown mb-0">
        <label class="filter__label tw-mt-2">ID сущности</label>
        <zem-input v-model="order" placeholder="Укажите ID" @input="handlerFilter('model_id', $event)" />
      </div>
      <div class="zem-dropdown mb-0">
        <label class="filter__label tw-mt-2">Пользователь</label>
        <ZemDropdownList
          v-model="selectedUser"
          :options="users"
          class="tw-mt-0"
          label="title"
          placeholder="Выбрать"
          @change="handlerFilter('created_by', $event)"
        ></ZemDropdownList>
      </div>
      <div class="filter__period-block">
        <label class="filter__label tw-mt-2">Дата действия</label>
        <date-picker
          v-model="rangeDate"
          format="DD.MM.YYYY"
          locale="ru"
          placeholder="Выбрать период"
          range
          value-type="format"
          @change="handlerDate('created_at', $event)"
        >
          <template v-slot:footer>
            <div style="text-align: left" class="tw-grid tw-grid-cols-4 tw-gap-3 tw-w-full">
              <button
                v-for="shortcut in shortcuts"
                :key="shortcut"
                class="tw-text-xs tw-font-normal tw-text-[#000000ca] tw-p-1.5 tw-rounded tw-bg-[#F5F5F5] hover:tw-bg-[#0DB2B2] hover:tw-text-white tw-transition-all"
                @click="onShortcutClick('rangeDate', shortcut)"
              >
                {{ shortcut }}
              </button>
            </div>
          </template>
        </date-picker>
      </div>
      <div class="zem-dropdown mb-0">
        <label class="filter__label tw-mt-2">Действие</label>
        <ZemDropdownList
          v-model="selectedEvent"
          :options="events"
          class="tw-mt-0"
          label="title"
          placeholder="Выбрать"
          @change="handlerFilter('event_type', $event)"
        ></ZemDropdownList>
      </div>
    </div>
  </div>
</template>

<script>
import ZemMultiSelect from '@/components/ui/ZemMultiSelect'
import ZemIcon from '@/components/ui/ZemIcon'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ru'
import ZemInput from '@/components/ui/ZemInput.vue'
import {MinusIcon} from 'vue-feather-icons'
import ZemDropdownList from '@/components/ui/ZemDropdownList.vue'
import moment from 'moment/moment'
import UsersService from '@/services/users.service'
import {selectDay} from '@/assets/scripts/scripts'
import shortcuts from '@/assets/scripts/shortcuts'

export default {
  name: 'FilterObjectsForm',

  components: {
    ZemInput,
    ZemDropdownList,
    ZemMultiSelect,
    ZemIcon,
    MinusIcon,
    DatePicker,
  },

  data() {
    return {
      OpenIndicator: {
        render: createElement => createElement('span', ''),
      },
      rangeDate: null,
      order: '',
      users: [],
      modelNotes: [],
      selectedEvent: {
        title: '',
        value: '',
      },
      selectedUser: {
        title: '',
        value: '',
      },
      selectedModel: {
        title: '',
        value: '',
      },
      timer: null,
      filterData: {},
      shortcuts,
    }
  },

  mounted() {
    UsersService.getUsersList().then(r => {
      this.users = r.data.data
    })
  },

  computed: {
    events() {
      return this.$store.state.history.historyPagination.custom.event_types
    },
  },

  methods: {
    selectDay,
    handlerFilter(field, e) {
      const isObject = Object.hasOwn(e, 'title')
      const value = isObject ? e.value : e
      this.filterData[field] = isObject ? e.value : e
      if (!value) delete this.filterData[field]
      this.getFilter()
    },
    handlerDate(field, e) {
      const startDate = moment(e[0], 'DD.MM.YYYY').format('YYYY-MM-DD')
      const endDate = moment(e[1], 'DD.MM.YYYY').format('YYYY-MM-DD')
      this.filterData[field] = `${startDate}|${endDate}`
      if (!e[0] && !e[1]) delete this.filterData[field]
      this.getFilter()
    },
    getFilter() {
      clearTimeout(this.timer)
      this.timer = null
      this.timer = setTimeout(() => {
        let data = ''
        let counter = 0
        for (let key in this.filterData) {
          data += key + ':' + this.filterData[key]
          counter++
          if (Object.keys(this.filterData).length !== counter) data += ';'
        }
        this.$store.commit('history/filterMain', data)
        this.$emit('on-update-table')
      }, 500)
    },
    resetFilter() {
      this.$store.commit('users/getSearch', '')
      this.rangeDate = null
      this.filterData = {}
      this.order = ''
      this.selectedUser = {
        title: '',
        value: '',
      }
      this.selectedEvent = {
        title: '',
        value: '',
      }
      this.getFilter()
    },
    closeLeftSidebar() {
      this.$store.commit('sidebars/changeLeftSidebar', false)
    },
    onShortcutClick(type, shortcut) {
      this[type] = selectDay(shortcut)
      this.handlerDate(type, this[type])
    },
  },
}
</script>

<style lang="scss">
.filter {
  .mx-datepicker {
    width: 100%;
  }

  .mx-input-wrapper {
    .mx-input {
      font-weight: 400;
      font-size: 12px;
      line-height: 13px;
      padding: 6px 6px 6px 8px;
      height: unset;
      border: 1px solid #f0f0f0;
      -webkit-box-shadow: unset;
      box-shadow: unset;
    }

    .mx-input:hover,
    .mx-input:focus {
      border-color: $color-accent;
    }

    .mx-input::-webkit-input-placeholder {
      color: #9ba6b3;
    }

    .mx-input::-moz-placeholder {
      color: #9ba6b3;
    }

    .mx-icon-calendar {
      color: #9ba6b3;
      font-size: 13px;
    }
  }
}
</style>

<style lang="scss" scoped>
.filter {
  display: block;
  padding: 32px 24px;
  min-width: 192px;

  &__btn-block {
    display: flex;
    justify-content: space-between;
  }

  &__btn-left {
    display: flex;
    align-items: center;

    &__label {
      font-weight: 700;
      font-size: 12px;
      line-height: 13px;
      color: $color-mine-shaft;
    }

    &__reset-btn {
      font-weight: 600;
      font-size: 12px;
      line-height: 13px;
      color: $color-accent;
      text-align: right;
      flex: 1;
      margin-left: 8px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__label {
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    color: $color-gull-gray;
    text-align: left;
    margin-top: 16px;
    margin-bottom: 4px;
  }

  &__checkbox-block {
    margin-top: 8px;

    label:not(:first-child) {
      margin-top: 8px;
    }
  }

  &__period-block {
    display: flex;
    flex-direction: column;
  }

  &__btn-period {
    width: 100%;
    padding: 5px 6px 5px 8px;
    border: 1px solid $color-catskill-grey;
    border-radius: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
    cursor: pointer;
    background-color: $color-white;
    color: $color-gull-gray;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
      background: $color-iron;
      border: 1px solid $color-iron;
      //color: $color-oslo-gray;
      opacity: 0.8;
    }
  }

  &__icon-period {
    width: 16px;
    height: 16px;
    background-image: url('../../assets/icons/calendar.svg');
  }

  &__dropdown {
    margin-top: 8px;
  }
}

@media screen and (max-width: 1440px) {
  .filter {
    padding: 20px;
    min-width: 152px;
  }
}
</style>
